header, footer {
  display: none; }

a[href]:after {
  content: ""; }

.label {
  border: none; }

.main_bottom,
.main_top,
#modules_nav_side {
  display: none; }
